/*
 |-----------------------------------------------------------------------------
 | components/molecules/Modal/Modal.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect, useRef } from 'react';

// import { ray } from 'node-ray/web';

import { modalSelector, toggleModal } from '@/features/modal';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import Title from '@/atoms/Title';

import * as IModal from './types';

const Modal: FC<IModal.IProps> = ({
	children,
	id,
	lightbox,
	title,
	utilities = '',
}) => {
	// ray('Debug molecule Modal:', {
	// 	id: id,
	// 	lightbox: lightbox,
	// 	title: title,
	// 	utilities: utilities,
	// }).blue();

	const dispatch = useAppDispatch();
	const modalRef = useRef(id);

	const modalState = useAppSelector(modalSelector);

	useEffect(() => {
		document.addEventListener('keyup', e => {
			if (e.key === 'Escape') {
				dispatch(toggleModal(false));
			}
		});

		const dialog = modalRef.current;

		switch (modalState) {
			case false:
				dialog?.close();
				break;
			case true:
				dialog?.showModal();
				break;
		}
	}, [modalState]);

	const closeModal = () => {
		dispatch(toggleModal(false));
	};

	return (
		<dialog
			// className={`
			// 	fixed
			// 	h-full
			// 	inset-0
			// 	my-0
			// 	px-8
			// 	w-full
			// 	z-50
			// 	${lightbox ? 'bg-black bg-opacity-75' : 'bg-brand-3'}
			// 	${utilities ? utilities : ''}
			// `}
			className={`
				backdrop:bg-brand-3
				bg-transparent
				p-8
				md:p-4
				${utilities}
			`}
			ref={modalRef}
		>
			<button
				className={`
					block
					h-11
					mb-16
					ml-auto
					relative
					w-11
					after:absolute
					after:bg-brand-1
					after:h-9
					after:left-1/2
					after:top-1/2
					after:w-0.5
					before:absolute
					before:bg-brand-1
					before:h-9
					before:left-1/2
					before:top-1/2
					before:w-0.5
					after:-translate-x-1/2
					after:-translate-y-1/2
					after:-rotate-45
					before:-translate-x-1/2
					before:-translate-y-1/2
					before:rotate-45
					${utilities ? utilities : ''}
				`}
				onClick={closeModal}
				type="button"
			>
				<span className={`sr-only`}>Close modal</span>
			</button>

			<div
				className={`
					mx-auto
					w-full
					${lightbox ? 'max-w-[1280px]' : 'max-w-[850px]'}
				`}
			>
				<Title
					level={1}
					text={title}
					utilities={`
						font-normal
						font-secondary
						text-7xl
						text-brand-1
						md:text-5xl
						custom:text-4xl
					`}
				/>

				{children}
			</div>
		</dialog>
	);
};

export default Modal;
