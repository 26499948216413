/*
 |-----------------------------------------------------------------------------
 | components/atoms/Title/Title.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as ITitle from './types';

const Title: FC<ITitle.IProps> = ({
	children,
	level,
	schema,
	srOnly,
	text,
	tinaField,
	utilities,
}) => {
	// ray('Debug atom Title:', {
	// 	level: level,
	// 	schema: schema,
	// 	text: text,
	// 	tinaField: tinaField,
	// 	utilities: utilities,
	// }).red();

	const Tag: any = `h${level}`;

	return (
		<Tag
			className={`
				${srOnly ? 'sr-only' : ''}
				${utilities ? utilities : ''}
			`}
			data-testid="title"
			data-tinafield={tinaField}
			itemProp={schema}
		>
			{children || text}
		</Tag>
	);
};

export default Title;
